<template>
  <v-app>
    <v-container fluid fill-height style="height:100%; margin:0;padding:0;">
      <v-row align="center" justify="center" style="height:100%; margin:0;padding:0; text-align: center;"
        class="glass fadein" :class="{ opaHidden: unlock }">
        <v-col cols="12">
          Pssst...Parole?<br />
          <input type="password" size="30" class="inputfield" :class="{ shake: shakeFlag }" v-model="pw" /><br /><br />
          <v-btn @click="checkPw()">Weiter</v-btn>
        </v-col>
      </v-row>
      <v-row style="height:100%;" class="fadein" :class="{ opaHidden: !unlock }">
        <v-col cols="12" md="3">
          <br />
          <!-- <h1>This is NBBB <strong>XX</strong>.</h1> -->
          <Countdown class="brdr"></Countdown>
        </v-col>
        <v-col offset-md="2" cols="12" md="7" align-self="end">
          <v-expansion-panels class="glass brdr">            
            <v-expansion-panel class="glass">
              <v-expansion-panel-header class="hdr">
                <v-icon color="white" style="flex: none; margin-right: 0.5em;">mdi-hammer</v-icon>Mitwirken
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                Freut uns wenn du das Fest mitgestalten möchtest! Es gibt mehrere Möglichkeiten:
                <br /><br />
                <b>Support:</b> Falls du Lust hast eine oder mehrere Schichten am Festival mitzuhelfen, zum Beispiel an der Bar, dann <a href="https://forms.gle/qWLwU1UMZDo1S7ug6">hier lang</a>
                <br/><br />
                <b>Beiträge:</b> Falls du Musik machen möchtest, einen Workshop anbieten, oder auch was ganz anderes dann <a href="https://forms.gle/r6vmtMd3A5zZEW3a8">hier lang</a>
                <br/><br />
                <b>Orga:</b> Falls du Teil des Orga-Teams bist, gehts zur Chatplattform <a href="https://chat.nossbros.de">hier lang</a>.
                Du brauchst zum Beitreten aber einmalig einen Einladungslink, dieser erreicht dich via deinem Kontakt zum NBBB.
              </v-expansion-panel-content>
            </v-expansion-panel>            
            <v-expansion-panel class="glass">
              <v-expansion-panel-header class="hdr">
                <v-icon color="white" style="flex: none; margin-right: 0.5em;">mdi-information-outline</v-icon>Infos
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                An dieser Stelle findest du bald weitere Infos wie FAQ, Anreise oder das Lineup. Schau in ein paar Wochen nochmal rein!
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel class="glass">
              <v-expansion-panel-header class="hdr">
                <v-icon color="white" style="flex: none; margin-right: 0.5em;">mdi-chat</v-icon>Feedback & Fragen
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                Immer her damit (gerne auch mit Links zu coolen Ideen oder ähnlichem). Falls du eine Antwort möchtest, pack bitte auch eine Kontaktmöglichkeit mit in die Nachricht.
                <br /><br />
                <textarea v-model="fbtext" class="fbentry"></textarea><br /><br>
                <button class="button-6" @click="sendFB">
                  <span v-if="sendStatus == 'none'">Feedback absenden</span>
                  <span v-if="sendStatus == 'sending'">Sende...</span>
                  <span v-if="sendStatus == 'sent'">Danke!</span>
                </button>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
import axios from 'axios'
import Countdown from './components/Countdown.vue'

export default {
  name: 'App',
  components: {
    Countdown
  },
  data: function () {
    return {
      fbtext: "",
      sendStatus: "none",
      pw: "",
      shakeFlag: false,
      unlock: false,
    }
  },
  methods: {
    sendFB: function () {
      this.sendStatus = "sending"
      if (this.fbtext.length > 3)
        console.log("send")
      axios.post("https://mm.nbbb.fl0.it/hooks/giobg1f3xtr3dn7qkgoidexq3w", {
        text: this.fbtext
      }).then(() => {
        this.sendStatus = "sent"
      });
    },
    checkPw: function () {
      let hashed = btoa(this.pw);
      axios.get(window.location.origin + "/" + hashed + ".json", {
      }).then((r) => {
        this.content = r.data;
        this.unlock = true;
        document.cookie = "login=" + this.pw;
      }).catch((error) => {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
          this.shakeFlag = true;
          setTimeout(() => {
            this.shakeFlag = false;
          }, 400);
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error', error.message);
        }
        console.log(error.config);
      });
    },
    getCookie: function(cname) {
      let name = cname + "=";
      let decodedCookie = decodeURIComponent(document.cookie);
      let ca = decodedCookie.split(';');
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
        }
      }
      return "";
    }
  },
  mounted() {
    if(this.getCookie("login") != "")  {
      this.pw = this.getCookie("login");
      this.checkPw();
    }
  }
}
</script>

<style>
#app {
  height: 100vh;
  width: 100vw;
  margin: 0;
  padding: 0;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background-image: url('./assets/DSC07845.jpg');
  background-position: center center;
  background-size: cover;
  background-color: white;
  color: white;
  text-align: left;
  overflow: hidden;
}

h1 {
  font-weight: 200;
}

.brdr {
  /* border:2px solid white;   */
}

@keyframes shake {
  0% {
    transform: translate(0);
  }

  20% {
    transform: translate(1em);
  }

  40% {
    transform: translate(-1em);
  }

  60% {
    transform: translate(1em);  
  }

  80% {
    transform: translate(-1em);
  }

  100% {
    transform: translate(0);
  }
}

.hdr {
  font-weight: 900;
  font-size: 14pt;
  text-align: left;
  padding-left:1.0em;
}
  
.v-icon {
  color:white !important;
  display:inline !important;
  width: 20px !important;
}

.opaHidden {
  opacity: 0;
  z-index: -999;
  display: none;
}

.fadein {
  transition: opacity 0.3s ease-in-out;
}

.hdr {
  font-weight: 900;
  font-size: 10pt;
  text-align: left;
  padding-left: 1.0em;
}

.v-icon {
  color: white !important;
  display: inline !important;
  width: 20px !important;
}

.fbentry {
  width: 90%;
  height: 200px;
  border: 2px solid white;
  color: white;
  background-color: rgba(112, 112, 112, 0.609);
  padding: 15px;
  text-shadow: 1px 1px 1px black;
}

.actions {
  display: inline-block;
  min-width: 480px;
  background-color: rgba(255, 255, 255, 0.123);
  padding-top: 60px;
  padding-bottom: 100px;
  color: black;
}

.glass {
  background-color: transparent !important;
  backdrop-filter: blur(24px);
  color: white !important;
}

.inputfield {
  border: 2px solid white;
  font-size: 15pt;
  color: white;
}

.inputfield.shake {
  animation: shake 0.5s infinite;
}


/* CSS */
.button-6 {
  align-items: center;
  background-color: #FFFFFF;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: .25rem;
  box-shadow: rgba(0, 0, 0, 0.02) 0 1px 3px 0;
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.85);
  cursor: pointer;
  display: inline-flex;
  font-family: system-ui, -apple-system, system-ui, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 16px;
  font-weight: 600;
  justify-content: center;
  line-height: 1.25;
  margin: 0;
  min-height: 3rem;
  padding: calc(.875rem - 1px) calc(1.5rem - 1px);
  position: relative;
  text-decoration: none;
  transition: all 250ms;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: baseline;
  width: auto;
}

.button-6:hover,
.button-6:focus {
  border-color: rgba(0, 0, 0, 0.15);
  box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
  color: rgba(0, 0, 0, 0.65);
}

.button-6:hover {
  transform: translateY(-1px);
}

.button-6:active {
  background-color: #F0F0F1;
  border-color: rgba(0, 0, 0, 0.15);
  box-shadow: rgba(0, 0, 0, 0.06) 0 2px 4px;
  color: rgba(0, 0, 0, 0.65);
  transform: translateY(0);
}

body,
html {
  margin: 0;
  padding: 0;
}</style>