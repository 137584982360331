<template>
  <div class="cd_main">
    <div class="cd_container">
      <div class="cd_value">{{days}}</div>
      <div class="cd_smaller">days</div>
    </div>    
    <div class="cd_container">
      <div class="cd_value">{{hours}}</div>
      <div class="cd_smaller">hours</div>
    </div>    
    <div class="cd_container">
      <div class="cd_value">{{minutes}}</div>
      <div class="cd_smaller">minutes</div>
    </div>    
    <div class="cd_container">
      <div class="cd_value">{{seconds}}</div>
      <div class="cd_smaller">seconds</div>
    </div>    
  </div>
</template>

<script>

  export default {
    name: 'App',
    components: {},
    data: function () {
      return {
        cnttext: "LOADING",
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
        fbtext: ""
      }
    },
    methods: {
      initCountdown: function () {
        let countDownDate = new Date("Aug 10, 2023 16:00:00").getTime();
        setInterval(() => {
          // Get today's date and time
          var now = new Date().getTime();

          // Find the distance between now and the count down date
          var distance = countDownDate - now;

          // Time calculations for days, hours, minutes and seconds
          this.days = Math.floor(distance / (1000 * 60 * 60 * 24));
          this.hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
          this.minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
          this.seconds = Math.floor((distance % (1000 * 60)) / 1000);
        }, 1000);
      }
    },
    mounted() {
      this.initCountdown();
    }
  }
</script>

<style scoped>
  .cd_main {    
    background-color: transparent;
    backdrop-filter: blur(24px);
  }

  .cd_container {
    position: relative;
    top:0;
    left:0;
  }

  .cd_value {
    font-size: 10vw;
    color: white;
    line-height: 10vw;
    text-align: left;
    text-shadow: 1px 1px 18px black;
    position: relative;
    top:0;
    left:0;
  }

  .cd_smaller {
    text-shadow: 1px 1px 18px black;
    position: absolute;
    bottom:35%;
    left:0%;
    z-index:9999;
    color: white;
    text-shadow: 1px 1px 2px black;
    mix-blend-mode:difference;
    text-align: left;
    /* margin-top: -4.5vh;
    margin-left: 2pt; */
    font-size: 2vw;
    line-height: 1vw;
  }
</style>
